import React, { FC, useState } from "react"
import { Box, Grid, Typography } from "@mui/material"

import ZoomableAvatar from "./zoomableAvatar"

interface Wallets {
    wallets: {
        id: string
        logo: string
    }[]
    setPaymentMethod: (value: React.SetStateAction<string>) => void
}
export const WalletsList: FC<Wallets> = ({ wallets, setPaymentMethod }) => {
    const [selectedItemId, setSelectedItemId] = useState<any>(null)

    const handleItemSelection = (itemId: any) => {
        setSelectedItemId(itemId)
        setPaymentMethod(itemId)
    }

    return (
        <React.Fragment>
            <Typography sx={{ fontWeight: "bold", fontSize: 16, mb: 1 }}>
                Choose Wallet
            </Typography>
            <Box
                sx={{
                    maxHeight: `calc(100vh - 412px)`,
                    overflow: "auto",
                    pr: 1,
                }}
            >
                <Grid container rowSpacing={3} columnSpacing={1}>
                    {wallets?.map((item) => (
                        <Grid item xs={6} sm={6} md={4} key={item?.id}>
                            <ZoomableAvatar
                                image={item?.logo}
                                id={item?.id}
                                isSelected={selectedItemId === item?.id}
                                handleSelection={handleItemSelection}
                                setPaymentMethod={setPaymentMethod}
                            />
                        </Grid>
                    ))}
                </Grid>
                {/* <TextField
          fullWidth
          variant="outlined"
          label="Phone Number"
          size="small"
          sx={{
            mt: 4,
          }}
        /> */}
            </Box>
        </React.Fragment>
    )
}
